import request from '@/utils/request'

// 手機版圖庫名稱
export const getCategoryName = function(query) {
  return request({
    url: 'v1/cp168/apis/tukus/categories',
    method: 'get',
    params: {
      id: query.join(',')
    }
  })
}

// 電腦版圖庫列表
export const getTukusList = function(query) {
  return request({
    url: 'v1/cp168/apis/tukus/categoriesByPinyin',
    method: 'get',
    params: query
  })
}

// 電腦版獲取圖庫圖片
export const getTukus = function(query) {
  return request({
    url: 'v1/cp168/apis/tukus/images',
    method: 'get',
    params: query
  })
}

// 電腦版報紙期數列表
export const getNewsPaperPeriod = function(query) {
  return request({
    url: 'v1/cp168/apis/newspaper/period',
    method: 'get',
    params: query
  })
}

// 電腦版取得canvas報紙
export const getNewsPaperURL = function(query) {
  return request({
    url: 'v1/cp168/apis/newspaper/imgURL',
    method: 'get',
    params: query
  })
}

// 電腦版上傳canvas圖片
export const postCanvasNewsPaper = function(payload) {
  return request({
    url: 'v1/cp168/apis/newspaper/upload',
    method: 'post',
    data: payload
  })
}

// 過去十期生肖以及命中率
export const getPastTenPeriod = function(cpCode, period) {
  return request({
    url: `/v1/cp168/apis/newspaper/predict/pastTenPeriod/${cpCode}/${period}`,
    method: 'get'
  })
}

// 上期預測結果
export const getLastPastTenPeriod = function(type, cpCode, period) {
  return request({
    url: `/v1/cp168/apis/newspaper/predict/lastPeriodPrediction/${type}/${cpCode}/${period}`,
    method: 'get'
  })
}

// 指定期數的預測
export const getSpecifiedPeriodPrediction = function(cpCode, period) {
  return request({
    url: `/v1/cp168/apis/newspaper/predict/period/${cpCode}/${period}`,
    method: 'get'
  })
}
